@import "variables";

/********************************  SITEMAP ***********************************************/
#sitemap {
  text-align: center;
  ul li {
    list-style: none;
    a {
      font-family: $font-secondary;
      color: $color-main;
      font-size: 22px;
      font-weight: 700;
      padding: 10px 0px;
      text-transform: uppercase;
    }

    ul li a {
      font-family: $font-secondary;
      color: $color-third;
      font-weight: 600;
    }
  }
}

/********************************  NewsBundle ***********************************************/
.news_index {
  .new {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 2px solid $color-secondary;
  }
}

/********************************  SliderBundle ***********************************************/

/* Slider */
.slider_principal {
  margin-bottom: 40px;
}

.slider {
  position: relative;

  img {
    width: 100%;
  }
  &::before {
    content: "";
    background: url("../../../../../web/images/maison-de-la-literie/vague.svg");
    position: absolute;
    bottom: 85px;
    width: 100%;
    height: 10%;
    background-size: 100%;
    background-repeat: repeat-x;
    z-index: 1001;
  }

  .slide-presentation {
    padding-top: 10px;

    span {
      font-size: 40px;
      color: $color-main;
    }

    p {

    }
  }

}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-slide {
  float: left;
  overflow: hidden;
  text-align: center;
}

.slick-list {
  overflow: hidden;
}

.slick-next {
  position: absolute;
  z-index: 1000;
  bottom: 5%;
  right: 12%;
  border: none;
  cursor: pointer;
  width: 45px;
  height: 44px;
  background: url("../../../../../web/images/maison-de-la-literie/Arrow-right.svg");
}

.slick-prev {
  position: absolute;
  z-index: 1000;
  bottom: 5%;
  left: 12%;
  border: none;
  cursor: pointer;
  width: 45px;
  height: 44px;
  background: url("../../../../../web/images/maison-de-la-literie/Arrow-left.svg");
}

//DOTS
// Styling the default Slick dots

.slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: -40px;

  li {
    margin: 0 0.25rem;
  }

  button {
    display: block;
    width: 1rem;
    height: 1rem;
    padding: 0;

    border: none;
    border-radius: 100%;
    background-color: $color-text-main;

    text-indent: -9999px;
  }

  li.slick-active button {
    background-color: $color-main;
  }

}

@media all and (max-width: 991px) {
  .slider {
    .slide-presentation {
      margin: 0 20%;
      padding-top: 50px;
    }
    img {
      height: 320px;
    }
    &:before {
      bottom: unset;
      top: 292px;
    }
  }
  .slick-next {
    bottom: 4%;
  }
  .slick-prev {
    bottom: 4%;
  }
}

@media all and(max-width: 575px) {
  .slider {
    img {
      height: 160px;
    }

    .slide-presentation {
      margin: 0;
      padding: 27% 20px 0 20px;

      .slideTitle {
        font-size: 30px;
      }
    }
    &:before {
      top: 135px;
      background-position: center;
      background-size: 130%;
    }
  }
  .slick-prev {
    top: 190px;
    bottom: unset;
    left: 30%;
  }

  .slick-next {
    top: 190px;
    right: 30%;
    bottom: unset;
  }
}