@import "variables";

.zone.container {
  .block_separator {
    border-bottom: 3px solid $color-secondary;
    margin-bottom: 30px;
  }
}

.block {
  margin-bottom: 30px;

  .visual_container {
    position: relative;

    .link, .zoom {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: block;
      opacity: 0;
      background: url('../../../../images/link.png') center center $color-secondary no-repeat;
      @extend .transition-main;
    }
    .zoom {
      background: url('../../../../images/zoom.png') center center $color-secondary no-repeat;
    }
    &:hover {
      .link, .zoom {
        opacity: 0.7;
      }

    }
  }
}

/********************************  Entete ***********************************************/
.block_entete {
  position: relative;
  .info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  img {
    width: 100%;
    height: 350px;
  }
  &::after {
    content: "";
    background: url("../../../../../web/images/maison-de-la-literie/vague.svg");
    position: absolute;
    width: 104%;
    height: 100px;
    background-size: 100%;
    background-repeat: repeat-x;
    bottom: -52px;
    left: -4px;
  }
}

.chiffre, .chiffres {
  text-align: center;
  padding: 30px 0px;

  .col {
    border-left: 2px solid $color-secondary;

    h2 {
      font-size: 50px;
    }
    .introduction {
      font-size: 24px;
      color: $color-secondary;
    }
  }
  .col:nth-child(1) {
    border: none;
  }
}

/********************************  CUSTOM CLASS ***********************************************/
.vague {
  .col {
    height: 100%;
  }

  .block_media {
    margin: 0 !important;
    &::after {
      content: "";
      background: url("../../../../../web/images/maison-de-la-literie/vague.svg");
      position: absolute;
      width: 100%;
      height: 40px;
      background-size: 160%;
      background-repeat: repeat-x;
      bottom: -15px;
      left: 0;
    }
  }
}

.padding-left-text {
  .block_text {
    padding-left: 20%;
  }
}

@media all and(max-width: 1350px) {
  .padding-left-text {
    .block_text {
      padding-left: 15%;
    }
  }
}

@media all and(max-width: 1199px) {
  .padding-left-text {
    .block_text {
      padding-left: 10%;
    }
  }
}

@media all and(max-width: 991px) {
  .padding-left-text {
    .block_text {
      padding-left: 0;
    }
  }
}

.vague-bg {
  margin: 0 !important;
  &::after {
    content: "";
    background: url("../../../../../web/images/maison-de-la-literie/vague-2-reverse.svg");
    position: absolute;
    width: 100%;
    height: 80px;
    background-repeat: repeat-x;
    bottom: -70px;
    left: 0;
    background-size: 100%;
  }
  &.bg {
    padding: 125px 0 60px 0 !important;
  }
}

.bg {
  position: relative;
  background-color: #f6f6f6;
  padding: 100px 0 200px 0;
}

.presentation {
  .block_media {
    margin: 0 10%;
    img {
      height: 100px;
    }
    h3 {
      font-size: 24px;
      color: $color-main;
    }
    .introduction {
      font-weight: normal;
    }
  }
}

.margin-text {
  .block_text {
    margin: 0 5%;
  }
}

.no-padding-text {
  .row {
    .col {
      .block_text {
        margin: 0 -15px;
      }
    }
  }
}

.align-vert {
  .block_text {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0 150px 0 65px;
  }
}

@media all and(max-width: 1350px) {
  .align-vert {
    .block_text {
      margin: 0 40px 0 40px;
    }
  }
}

@media all and(max-width: 991px) {
  .block_entete {
    img {
      height: 250px;
    }
    &::after {
      bottom: -72px;
    }
  }

  .align-vert {
    .block_text {
      position: unset;
      top: 0;
      -ms-transform: unset;
      transform: unset;
      margin: 0 15px 0 15px;
    }
  }

  .margin-text {
    .block_text {
      margin: 0 5%;
    }
  }
}

@media all and(max-width: 767px) {
  #tpl_cms {
    .bg {
      padding: 100px 0 100px 0;
    }
  }
}

@media all and(max-width: 575px) {
  .block_entete {
    img {
      height: 200px;
    }
    &::after {
      bottom: -84px;
    }
  }
}