@import "variables";

body.tpl-cookies {

    #tarteaucitronRoot,
    #tarteaucitronAlertBig,
    .modal-backdrop,
    #tarteaucitronPremium {
        display: none !important;
    }

    &.modal, &.modal-open {
        overflow: inherit;
    }

    .modal-backdrop.show{
        z-index: 10001;
    }
}

.modal-backdrop {
    z-index: 1400;
}

#tarteaucitronAlertBig {
    z-index: 1500;
    padding-right: 0 !important;

    .modal-body {

        padding: 60px;
        font-size: 18px;

        h2 {
            font-size: 25px;
            margin-bottom: 30px;
            color: #333;
        }
    }

    .btn-success {

        border: 1px solid $color-main !important;
        background-color: $color-main !important;
        color: #fff;
        text-transform: uppercase;
        border: 2px solid #fff;
        border-radius: 5px;
        line-height: 50px;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 2px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        transition: all .2s ease-in-out;
        white-space: nowrap;
        padding: 0 60px;

        &:hover {
            background-color: #fff !important;
            color: $color-main;
            transition: all .2s ease-in-out;
        }
    }
}

.embed-responsive {

    position: relative;

    .activate-wrap {

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #000;

        .tac_float {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            left: 0;
            text-align: center;
        }
    }
}

@media all and (max-width: 767px) {
    #tarteaucitronAlertBig .modal-body {
        padding: 10px;
        font-size: 14px;
    }
    #tarteaucitronAlertBig .modal-body .img-fluid{
        max-width: 50px;
    }
    #tarteaucitronAlertBig .modal-body h2 {
        font-size: 22px;
        margin-bottom: 15px;
    }
    #tarteaucitronAlertBig .modal-body #tarteaucitronAcceptContainer{
        margin-top: 20px !important;
    }
}