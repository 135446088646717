@import "variables";

#header {
  z-index: 1200;
  .blue-line-header {
    background: $color-main;
  }
    
  position: relative;
  .picto-header {
    display: table;
    position: absolute;
    right: 0;
    top: 5px;
    padding-right: 70px;

    li {
      display: table-cell;
      padding: 0px 30px;

      .picto-acces-padding {
        padding-top: 13px;
      }
      a {
        color: $color-main;
        font-size: $text-size-intro;
      }
    }

    .border-picto {
      background-color: #f1f1f1;
      border-radius: 25px;
      padding: 10px;
    }
    img {
      width: 25px;
    }
  }
  #logo {
    top: 30px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
    img {
      width: 350px;
    }
  }

  #navigation {
    display: inline-block;
  }
  .container {
    position: relative;
  }
}

@media all and(max-width: 1280px) {
  #header {
    #logo {
      top: 40px;
      img {
        width: 275px;
      }
    }

    .picto-header {
      padding-right: 40px;

      li {
        padding: 0 25px;
      }
    }
  }
}

@media all and(max-width: 991px) {
  #header {
    position: unset;
    .picto-header {
      top: 8px;
      z-index: 1002;
      position: fixed;

      li {
        padding: 0 10px;

        a {
          color: #fff;
        }
        p {
          display: inline;
          margin: 0 10px;
        }
      }
    }
    .blue-line-header {
      height: 80px;
      position: fixed;
      z-index: 1002;
      width: 100%;
    }
    #logo {
      top: 100px;

      img {
        width: 300px;
        max-width: unset;
      }
    }

    .white-responsive {
      padding: 90px 0;
    }
  }
}

@media all and(max-width: 675px) {
  #header {
    .picto-header {
      padding-right: 5px;

      li {
        p {
          display: none;
        }
      }
    }
  }
}