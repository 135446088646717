/***** Fonts *****/

/***** Colors *****/

/***** Texts sizes *****/

/***** transition *****/

.transition-main,
.bt_learn_more,
a.file,
input[type=submit],
.block .visual_container .link,
.block .visual_container .zoom {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

/***** cookie ****/

/***** Fonts *****/

/***** Colors *****/

/***** Texts sizes *****/

/***** transition *****/

.transition-main,
.bt_learn_more,
a.file,
input[type=submit],
.block .visual_container .link,
.block .visual_container .zoom {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

/***** cookie ****/

html {
  overflow-x: hidden;
}

#editMode {
  position: fixed;
  color: #003657;
  top: 0px;
  left: 0px;
  border: 1px solid #003657;
  background: #fff;
  padding: 10px 25px;
  border-radius: 0px 0px 5px 0px;
  z-index: 100000;
}

#editMode a {
  color: #003657;
}

a {
  color: #7b7b7b;
  text-decoration: none;
}

a:hover {
  color: unset;
  text-decoration: none;
}

img {
  max-width: 100%;
}

body {
  font-family: "Catamaran", sans-serif !important;
  font-size: 18px !important;
  color: #7b7b7b !important;
  font-weight: 500;
}

h1 {
  font-family: "Catamaran", sans-serif;
  font-size: 40px;
  color: #003657;
  font-weight: 600;
  padding: 20px 0px;
}

h2 {
  font-family: "Lato", sans-serif;
  color: #003657;
  font-weight: 600;
  padding: 10px 0px;
}

h3 {
  font-family: "Lato", sans-serif;
  color: #474747;
  font-weight: 600;
}

h4 {
  font-family: "Lato", sans-serif;
  color: #fff;
}

h5 {
  font-family: "Lato", sans-serif;
  color: #474747;
}

h6 {
  font-family: "Lato", sans-serif;
  color: #fff;
}

hr {
  color: #e3e3e3;
  width: 1000px;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.introduction {
  font-weight: 700;
  padding-bottom: 5px;
}

.no-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

#breadcrumb {
  background: #fff;
  color: #003657;
  border-radius: 0px;
  font-size: 12px;
  vertical-align: top;
  display: flex;
}

#breadcrumb .ici {
  display: inline-block;
  padding: 11px 0px 0px 15px;
}

#breadcrumb .breadcrumb {
  margin-bottom: 0px;
  background: none;
  color: #003657;
}

#breadcrumb .breadcrumb a,
#breadcrumb .breadcrumb .breadcrumb-item.active,
#breadcrumb .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #003657;
}

table tr td {
  padding: 5px;
  border: 1px solid #fff;
}

table thead td {
  background: #fff;
  color: #003657;
  font-weight: 700;
  font-size: 16px;
}

.bt_learn_more,
a.file,
input[type=submit] {
  display: inline-block;
  margin: 20px 0px;
  color: #003657;
  border: 1px solid #003657;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 700;
  padding: 5px 40px;
}

.bt_learn_more:hover,
a.file:hover,
input[type=submit]:hover {
  color: #fff;
  text-decoration: none;
  background: #003657;
}

blockquote {
  font-family: "Lato", sans-serif;
  font-size: 36px;
  color: #fff;
  position: relative;
  padding: 50px 0px;
  line-height: 40px;
  font-style: italic;
  font-weight: 300;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  text-align: center;
}

#btTop {
  display: inline-block;
  position: fixed;
  bottom: 25px;
  right: 25px;
  opacity: 0.8;
  background: #fff;
  color: #003657;
  font-size: 30px;
  text-align: center;
  width: 50px;
  height: 50px;
  z-index: 1000;
  cursor: pointer;
}

.form-group.has-error textarea,
.form-group.has-error select,
.form-group.has-error input {
  border: 1px solid #dc3545 !important;
}

.form-group.has-error .help-block {
  color: #dc3545 !important;
  font-size: 12px;
  margin-top: 3px;
  display: inline-block;
}

@media screen and (max-width: 780px) {
  blockquote {
    font-size: 26px;
  }

  blockquote:after {
    font-size: 50px;
    left: inherit;
    right: 0px;
  }

  #btTop {
    width: 25px;
    height: 25px;
    bottom: 15px;
    right: 15px;
    font-size: 20px;
  }

  h1 {
    font-size: 28px;
  }
}

/***** Responsive Grid => Evo grid responsive ****/

@media all and (max-width: 991px) {
  .col-six-and-threes {
    order: 5;
  }

  .col-six-and-threes.col-lg-6:nth-child(2) {
    order: 1;
  }
}

/***** Fonts *****/

/***** Colors *****/

/***** Texts sizes *****/

/***** transition *****/

.transition-main,
.bt_learn_more,
a.file,
input[type=submit],
.block .visual_container .link,
.block .visual_container .zoom {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

/***** cookie ****/

#header {
  z-index: 1200;
  position: relative;
}

#header .blue-line-header {
  background: #003657;
}

#header .picto-header {
  display: table;
  position: absolute;
  right: 0;
  top: 5px;
  padding-right: 70px;
}

#header .picto-header li {
  display: table-cell;
  padding: 0px 30px;
}

#header .picto-header li .picto-acces-padding {
  padding-top: 13px;
}

#header .picto-header li a {
  color: #003657;
  font-size: 16px;
}

#header .picto-header .border-picto {
  background-color: #f1f1f1;
  border-radius: 25px;
  padding: 10px;
}

#header .picto-header img {
  width: 25px;
}

#header #logo {
  top: 30px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1001;
}

#header #logo img {
  width: 350px;
}

#header #navigation {
  display: inline-block;
}

#header .container {
  position: relative;
}

@media all and (max-width: 1280px) {
  #header #logo {
    top: 40px;
  }

  #header #logo img {
    width: 275px;
  }

  #header .picto-header {
    padding-right: 40px;
  }

  #header .picto-header li {
    padding: 0 25px;
  }
}

@media all and (max-width: 991px) {
  #header {
    position: unset;
  }

  #header .picto-header {
    top: 8px;
    z-index: 1002;
    position: fixed;
  }

  #header .picto-header li {
    padding: 0 10px;
  }

  #header .picto-header li a {
    color: #fff;
  }

  #header .picto-header li p {
    display: inline;
    margin: 0 10px;
  }

  #header .blue-line-header {
    height: 80px;
    position: fixed;
    z-index: 1002;
    width: 100%;
  }

  #header #logo {
    top: 100px;
  }

  #header #logo img {
    width: 300px;
    max-width: unset;
  }

  #header .white-responsive {
    padding: 90px 0;
  }
}

@media all and (max-width: 675px) {
  #header .picto-header {
    padding-right: 5px;
  }

  #header .picto-header li p {
    display: none;
  }
}

/***** Fonts *****/

/***** Colors *****/

/***** Texts sizes *****/

/***** transition *****/

.transition-main,
.bt_learn_more,
a.file,
input[type=submit],
.block .visual_container .link,
.block .visual_container .zoom {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

/***** cookie ****/

.zone.container .block_separator {
  border-bottom: 3px solid #fff;
  margin-bottom: 30px;
}

.block {
  margin-bottom: 30px;
}

.block .visual_container {
  position: relative;
}

.block .visual_container .link,
.block .visual_container .zoom {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  background: url("./../../../images/link.png") center center #fff no-repeat;
}

.block .visual_container .zoom {
  background: url("./../../../images/zoom.png") center center #fff no-repeat;
}

.block .visual_container:hover .link,
.block .visual_container:hover .zoom {
  opacity: 0.7;
}

/********************************  Entete ***********************************************/

.block_entete {
  position: relative;
}

.block_entete .info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.block_entete img {
  width: 100%;
  height: 350px;
}

.block_entete::after {
  content: "";
  background: url("./../../../../web/images/maison-de-la-literie/vague.svg");
  position: absolute;
  width: 104%;
  height: 100px;
  background-size: 100%;
  background-repeat: repeat-x;
  bottom: -52px;
  left: -4px;
}

.chiffre,
.chiffres {
  text-align: center;
  padding: 30px 0px;
}

.chiffre .col,
.chiffres .col {
  border-left: 2px solid #fff;
}

.chiffre .col h2,
.chiffres .col h2 {
  font-size: 50px;
}

.chiffre .col .introduction,
.chiffres .col .introduction {
  font-size: 24px;
  color: #fff;
}

.chiffre .col:nth-child(1),
.chiffres .col:nth-child(1) {
  border: none;
}

/********************************  CUSTOM CLASS ***********************************************/

.vague .col {
  height: 100%;
}

.vague .block_media {
  margin: 0 !important;
}

.vague .block_media::after {
  content: "";
  background: url("./../../../../web/images/maison-de-la-literie/vague.svg");
  position: absolute;
  width: 100%;
  height: 40px;
  background-size: 160%;
  background-repeat: repeat-x;
  bottom: -15px;
  left: 0;
}

.padding-left-text .block_text {
  padding-left: 20%;
}

@media all and (max-width: 1350px) {
  .padding-left-text .block_text {
    padding-left: 15%;
  }
}

@media all and (max-width: 1199px) {
  .padding-left-text .block_text {
    padding-left: 10%;
  }
}

@media all and (max-width: 991px) {
  .padding-left-text .block_text {
    padding-left: 0;
  }
}

.vague-bg {
  margin: 0 !important;
}

.vague-bg::after {
  content: "";
  background: url("./../../../../web/images/maison-de-la-literie/vague-2-reverse.svg");
  position: absolute;
  width: 100%;
  height: 80px;
  background-repeat: repeat-x;
  bottom: -70px;
  left: 0;
  background-size: 100%;
}

.vague-bg.bg {
  padding: 125px 0 60px 0 !important;
}

.bg {
  position: relative;
  background-color: #f6f6f6;
  padding: 100px 0 200px 0;
}

.presentation .block_media {
  margin: 0 10%;
}

.presentation .block_media img {
  height: 100px;
}

.presentation .block_media h3 {
  font-size: 24px;
  color: #003657;
}

.presentation .block_media .introduction {
  font-weight: normal;
}

.margin-text .block_text {
  margin: 0 5%;
}

.no-padding-text .row .col .block_text {
  margin: 0 -15px;
}

.align-vert .block_text {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0 150px 0 65px;
}

@media all and (max-width: 1350px) {
  .align-vert .block_text {
    margin: 0 40px 0 40px;
  }
}

@media all and (max-width: 991px) {
  .block_entete img {
    height: 250px;
  }

  .block_entete::after {
    bottom: -72px;
  }

  .align-vert .block_text {
    position: unset;
    top: 0;
    -ms-transform: unset;
    transform: unset;
    margin: 0 15px 0 15px;
  }

  .margin-text .block_text {
    margin: 0 5%;
  }
}

@media all and (max-width: 767px) {
  #tpl_cms .bg {
    padding: 100px 0 100px 0;
  }
}

@media all and (max-width: 575px) {
  .block_entete img {
    height: 200px;
  }

  .block_entete::after {
    bottom: -84px;
  }
}

/***** Fonts *****/

/***** Colors *****/

/***** Texts sizes *****/

/***** transition *****/

.transition-main,
.bt_learn_more,
a.file,
input[type=submit],
.block .visual_container .link,
.block .visual_container .zoom {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

/***** cookie ****/

#footer {
  position: relative;
  margin-top: 40px;
}

#footer #logo_footer img {
  max-width: 250px;
  position: absolute;
  bottom: 32px;
  left: 70px;
}

#footer ul.info-footer {
  color: #003657;
  font-size: 16px;
  text-align: center;
  width: 100%;
  list-style: none;
  display: inline-block;
  font-weight: 600;
}

#footer ul.info-footer li {
  display: inline-block;
}

#footer ul.info-footer li:before {
  content: ' - ';
}

#footer ul.info-footer li:first-child:before {
  display: none;
}

#footer .footerpages {
  color: #7b7b7b;
  font-size: 12px;
  text-align: center;
  width: 100%;
  list-style: none;
  display: inline-block;
}

#footer .footerpages li.nav-item {
  display: inline-block;
}

#footer .footerpages li.nav-item:before {
  content: ' - ';
}

#footer .footerpages li.nav-item:first-child:before {
  display: none;
}

#footer .footerpages .nav-link {
  padding: 0px 5px;
  display: inline;
}

@media all and (max-width: 991px) {
  #footer #logo_footer img {
    bottom: -50px;
  }

  #footer .footer-data {
    width: 65%;
  }
}

@media all and (max-width: 767px) {
  #footer {
    text-align: center;
  }

  #footer #logo_footer img {
    position: unset;
  }

  #footer .footer-data {
    width: 100%;
  }

  #footer ul {
    padding-left: 0;
  }
}

/***** Fonts *****/

/***** Colors *****/

/***** Texts sizes *****/

/***** transition *****/

.transition-main,
.bt_learn_more,
a.file,
input[type=submit],
.block .visual_container .link,
.block .visual_container .zoom {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

/***** cookie ****/

/********************************  SITEMAP ***********************************************/

#sitemap {
  text-align: center;
}

#sitemap ul li {
  list-style: none;
}

#sitemap ul li a {
  font-family: "Lato", sans-serif;
  color: #003657;
  font-size: 22px;
  font-weight: 700;
  padding: 10px 0px;
  text-transform: uppercase;
}

#sitemap ul li ul li a {
  font-family: "Lato", sans-serif;
  color: #474747;
  font-weight: 600;
}

/********************************  NewsBundle ***********************************************/

.news_index .new {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 2px solid #fff;
}

/********************************  SliderBundle ***********************************************/

/* Slider */

.slider_principal {
  margin-bottom: 40px;
}

.slider {
  position: relative;
}

.slider img {
  width: 100%;
}

.slider::before {
  content: "";
  background: url("./../../../../web/images/maison-de-la-literie/vague.svg");
  position: absolute;
  bottom: 85px;
  width: 100%;
  height: 10%;
  background-size: 100%;
  background-repeat: repeat-x;
  z-index: 1001;
}

.slider .slide-presentation {
  padding-top: 10px;
}

.slider .slide-presentation span {
  font-size: 40px;
  color: #003657;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-slide {
  float: left;
  overflow: hidden;
  text-align: center;
}

.slick-list {
  overflow: hidden;
}

.slick-next {
  position: absolute;
  z-index: 1000;
  bottom: 5%;
  right: 12%;
  border: none;
  cursor: pointer;
  width: 45px;
  height: 44px;
  background: url("./../../../../web/images/maison-de-la-literie/Arrow-right.svg");
}

.slick-prev {
  position: absolute;
  z-index: 1000;
  bottom: 5%;
  left: 12%;
  border: none;
  cursor: pointer;
  width: 45px;
  height: 44px;
  background: url("./../../../../web/images/maison-de-la-literie/Arrow-left.svg");
}

.slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: -40px;
}

.slick-dots li {
  margin: 0 0.25rem;
}

.slick-dots button {
  display: block;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: #7b7b7b;
  text-indent: -9999px;
}

.slick-dots li.slick-active button {
  background-color: #003657;
}

@media all and (max-width: 991px) {
  .slider .slide-presentation {
    margin: 0 20%;
    padding-top: 50px;
  }

  .slider img {
    height: 320px;
  }

  .slider:before {
    bottom: unset;
    top: 292px;
  }

  .slick-next {
    bottom: 4%;
  }

  .slick-prev {
    bottom: 4%;
  }
}

@media all and (max-width: 575px) {
  .slider img {
    height: 160px;
  }

  .slider .slide-presentation {
    margin: 0;
    padding: 27% 20px 0 20px;
  }

  .slider .slide-presentation .slideTitle {
    font-size: 30px;
  }

  .slider:before {
    top: 135px;
    background-position: center;
    background-size: 130%;
  }

  .slick-prev {
    top: 190px;
    bottom: unset;
    left: 30%;
  }

  .slick-next {
    top: 190px;
    right: 30%;
    bottom: unset;
  }
}

/***** Fonts *****/

/***** Colors *****/

/***** Texts sizes *****/

/***** transition *****/

.transition-main,
.bt_learn_more,
a.file,
input[type=submit],
.block .visual_container .link,
.block .visual_container .zoom {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

/***** cookie ****/

body.tpl-cookies #tarteaucitronRoot,
body.tpl-cookies #tarteaucitronAlertBig,
body.tpl-cookies .modal-backdrop,
body.tpl-cookies #tarteaucitronPremium {
  display: none !important;
}

body.tpl-cookies.modal,
body.tpl-cookies.modal-open {
  overflow: inherit;
}

body.tpl-cookies .modal-backdrop.show {
  z-index: 10001;
}

.modal-backdrop {
  z-index: 1400;
}

#tarteaucitronAlertBig {
  z-index: 1500;
  padding-right: 0 !important;
}

#tarteaucitronAlertBig .modal-body {
  padding: 60px;
  font-size: 18px;
}

#tarteaucitronAlertBig .modal-body h2 {
  font-size: 25px;
  margin-bottom: 30px;
  color: #333;
}

#tarteaucitronAlertBig .btn-success {
  border: 1px solid #003657 !important;
  background-color: #003657 !important;
  color: #fff;
  text-transform: uppercase;
  border: 2px solid #fff;
  border-radius: 5px;
  line-height: 50px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .2s ease-in-out;
  white-space: nowrap;
  padding: 0 60px;
}

#tarteaucitronAlertBig .btn-success:hover {
  background-color: #fff !important;
  color: #003657;
  transition: all .2s ease-in-out;
}

.embed-responsive {
  position: relative;
}

.embed-responsive .activate-wrap {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
}

.embed-responsive .activate-wrap .tac_float {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
  text-align: center;
}

@media all and (max-width: 767px) {
  #tarteaucitronAlertBig .modal-body {
    padding: 10px;
    font-size: 14px;
  }

  #tarteaucitronAlertBig .modal-body .img-fluid {
    max-width: 50px;
  }

  #tarteaucitronAlertBig .modal-body h2 {
    font-size: 22px;
    margin-bottom: 15px;
  }

  #tarteaucitronAlertBig .modal-body #tarteaucitronAcceptContainer {
    margin-top: 20px !important;
  }
}

