@import "variables";

html{
  overflow-x: hidden;
}

#editMode {
  position: fixed;
  color: $color-main;
  top: 0px;
  left: 0px;
  border: 1px solid $color-main;
  background: $color-secondary;
  padding: 10px 25px;
  border-radius: 0px 0px 5px 0px;
  z-index: 100000;
  a {
    color: $color-main;
  }
}

a {
  color: $color-text-main;
  text-decoration: none;

  &:hover {
    color: unset;
    text-decoration: none;
  }
}

img {
  max-width: 100%;

}

body {
  font-family: $font-main !important;
  font-size: $text-size-main !important;
  color: $color-text-main !important;
  font-weight: 500;
}

h1 {
  font-family: $font-main;
  font-size: 40px;
  color: $color-main;
  font-weight: 600;
  padding: 20px 0px;
}

h2 {
  font-family: $font-secondary;
  color: $color-main;
  font-weight: 600;
  padding: 10px 0px;
}

h3 {
  font-family: $font-secondary;
  color: $color-third;
  font-weight: 600;
}

h4 {
  font-family: $font-secondary;
  color: $color-secondary;
}

h5 {
  font-family: $font-secondary;
  color: $color-third;
}

h6 {
  font-family: $font-secondary;
  color: $color-secondary;
}

hr {
  color: #e3e3e3;
  width: 1000px;
  border-top: 2px solid rgba(0,0,0,.1);
}

.introduction {
  font-weight: 700;
  padding-bottom: 5px;
}

.no-padding{
  padding-right: 0 !important;
  padding-left: 0 !important;
}

#breadcrumb {
  background: $color-secondary;
  color: $color-main;
  border-radius: 0px;
  font-size: 12px;
  vertical-align: top;
  display: flex;

  .ici {
    display: inline-block;
    padding: 11px 0px 0px 15px;
  }
  .breadcrumb {
    margin-bottom: 0px;
    background: none;
    color: $color-main;

    a, .breadcrumb-item.active,
    .breadcrumb-item + .breadcrumb-item::before {
      color: $color-main;
    }

  }
}

table {
  tr {
    td {
      padding: 5px;
      border: 1px solid $color-secondary;
    }
  }
  thead {
    td {
      background: $color-secondary;
      color: $color-main;
      font-weight: 700;
      font-size: 16px;
    }
  }
}

.bt_learn_more,
a.file,
input[type=submit] {
  display: inline-block;
  margin: 20px 0px;
  color: $color-main;
  border: 1px solid $color-main;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 700;
  padding: 5px 40px;

  @extend .transition-main;

}

.bt_learn_more:hover,
a.file:hover,
input[type=submit]:hover {
  color: $color-secondary;
  text-decoration: none;
  background: $color-main;
}

blockquote {
  font-family: $font-secondary;
  font-size: 36px;
  color: $color-secondary;
  position: relative;
  padding: 50px 0px;
  line-height: 40px;
  font-style: italic;
  font-weight: 300;
  border-top: 1px solid $color-secondary;
  border-bottom: 1px solid $color-secondary;
  text-align: center;
}

#btTop {
  display: inline-block;
  position: fixed;
  bottom: 25px;
  right: 25px;
  opacity: 0.8;
  background: $color-secondary;
  color: $color-main;
  font-size: 30px;
  text-align: center;
  width: 50px;
  height: 50px;
  z-index: 1000;
  cursor: pointer;
}

// Formulaires

.form-group.has-error {

  textarea, select, input {
    border: 1px solid #dc3545 !important;
  }

  .help-block {
    color: #dc3545 !important;
    font-size: 12px;
    margin-top: 3px;
    display: inline-block;
  }
}

@media screen and (max-width: 780px) {
  blockquote {
    font-size: 26px;

    &:after {
      font-size: 50px;
      left: inherit;
      right: 0px;
    }
  }

  #btTop {
    width: 25px;
    height: 25px;
    bottom: 15px;
    right: 15px;
    font-size: 20px;
  }
  h1 {
    font-size: 28px;
  }
}

/***** Responsive Grid => Evo grid responsive ****/

@media all and (max-width: 991px) { // Cas particulier avec une row ayany 3 / 6 / 3 en colonnes  afin de passer la col 6 en premier et toute largeur en MD
  .col-six-and-threes {
    order: 5;
  }
  .col-six-and-threes.col-lg-6:nth-child(2) {
    order: 1;
  }
}