@import "variables";

#footer {
  position: relative;
  margin-top: 40px;

  #logo_footer {
    img {
      max-width: 250px;
      position: absolute;
      bottom: 32px;
      left: 70px;
    }
  }
  ul.info-footer {
    color: $color-main;
    font-size: $text-size-second;
    text-align: center;
    width: 100%;
    list-style: none;
    display: inline-block;
    font-weight: 600;

    li {
      display: inline-block;

      &:before {
        content: ' - ';
      }
      &:first-child {
        &:before {
          display: none;
        }
      }
    }
  }

  .footerpages {
    color: #7b7b7b;
    font-size: $text-size-small;
    text-align: center;
    width: 100%;
    list-style: none;
    display: inline-block;

    li.nav-item {
      display: inline-block;

      &:before {
        content: ' - ';
      }

    }
    li.nav-item:first-child {
      &:before {
        display: none;
      }
    }

    .nav-link {
      padding: 0px 5px;
      display: inline;
    }
  }
}

@media all and(max-width: 991px) {
  #footer {
    #logo_footer {
      img {
        bottom: -50px;
      }
    }

    .footer-data {
      width: 65%;
    }
  }
}

@media all and(max-width: 767px) {
  #footer {
    text-align: center;

    #logo_footer {
      img {
        position: unset;
      }
    }

    .footer-data {
      width: 100%;
    }
    ul{
      padding-left: 0;
    }
  }
}